import React from "react"

const BASE_URL = "47.99.220.122:8000"
export const isBrowser = () => typeof window !== "undefined"

// export const getUser = () =>
//   isBrowser() && window.localStorage.getItem("gatsbyUser")
//     ? JSON > parseFloat(window.localStorage.getItem("gatsbyUser"))
//     : {}

export const getUser = () => {
  if (isBrowser() && window.localStorage.getItem("gatsbyUser")) {
    console.log("Your getuser passed")

    return JSON.parse(window.localStorage.getItem("gatsbyUser"))
  } else {
    console.log("Your getuser not passed")
    return {}
  }
}

const setUser = user =>
  window.localStorage.setItem("gatsbyUser", JSON.stringify(user))

const setEnergy = energy => window.localStorage.setItem("energy", energy)

const getEnergy = () => {
  if (isBrowser() && window.localStorage.getItem("energy")) {
    console.log("your getEnergy passed")
    return window.localStorage.getItem("energy")
  } else {
    console.log("your getenergy not passed")
    return 0
  }
}

export const handleLogin = async (username, password) => {
  const apiLoginUrl = BASE_URL + "/api/token/"
  const method = "post"
  const headers = { "Content-Type": "application/json" }
  const body = JSON.stringify({ username, password })

  const response = await fetch(apiLoginUrl, { method, headers, body })

  if (response.ok) {
    console.log("[handleLogin] successfully logged in")
    const bodyasJson = await response.json()
    const { access, refresh } = bodyasJson
    setUser({ refresh, access, username })
    setEnergy(0)
  }
  if (response.status === 401) {
    throw "unauthorized"
  }
  // throw "unhandled error"
}
export const getColumnName = i =>
  (i >= 26 ? getColumnName(Math.floor(i / 26) - 1) : "") +
  "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[i % 26]

export const refreshToken = async () => {
  const apiLoginUrl = BASE_URL + "/api/token/refresh/"
  const method = "post"
  const headers = { "Content-Type": "application/json" }
  const refresh = getUser().refresh
  const username = getUser().username
  const body = JSON.stringify({ refresh })
  console.log({ body })
  const response = await fetch(apiLoginUrl, { method, headers, body })
  if (response.ok) {
    console.log("[refresh token] succesfully")
    const bodyasJson = await response.json()
    const access = bodyasJson.access
    setUser({ refresh, access, username })
  }
  if (response.status === 401) {
    throw "refresh token not correct "
  }
}

export const isLoggedIn = () => {
  const user = getUser()
  console.log("get user name as " + user.username)
  return !!user.username
}

export const logout = callback => {
  setUser({})
  console.log("current user should be none")
  console.log(`After logout your username should be${getUser().username}`)
  callback()
}
