import React from "react"
import { Link } from "gatsby"
import { getUser, isLoggedIn } from "../services/auth"
import { StaticImage } from "gatsby-plugin-image"

export default function Home() {
  return (
    <span>
      <div className="center">
        <StaticImage
          src="../images/logo.jpeg"
          width={300}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="A Gatsby astronaut"
          style={{ marginBottom: `1.45rem` }}
        />
        <h1> Hi {isLoggedIn() ? getUser().name : "Survivor"}!</h1>

        <p>
          {isLoggedIn() ? (
            <span>
              <p>欢迎回来你的家园系统</p>
              <Link to="/app/home" style={{ color: "black" }}>
                进入
              </Link>
            </span>
          ) : (
            <>
              <p>点击这里开启你的一月赛之旅</p>
              <Link to="/app/login" style={{ color: "black" }}>
                登陆
              </Link>
            </>
          )}
        </p>
      </div>
    </span>
  )
}
